
html, body { height: 100%; }
body { margin: 0;}
span.sr-only {
    display: none !important;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

@media
	  only screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {
        span.badge.badge-primary.badge-pill.font-weight-normal {
            display: none;
        }

        .linked-account-badge-mobile {
            display: inline-block !important;
        }
    }

    .linked-account-badge-mobile {
        display: none;
    }